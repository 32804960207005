import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import makeStyles from '@mui/styles/makeStyles';

import psonoSaasInstanceService from "../../services/psono-saas-instances";
import Table from "../../container/table";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles((theme) => ({
    iconButton: {},
    waitingIndicator: {
        marginLeft: theme.spacing(1),
    },
}));

function PsonoSaasListTable({project}) {
    const history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation();
    const [psonoSaasInstances, setPsonoSaasInstances] = useState([]);

    const editableStates = ["enabled"];
    const waitingStates = ["provisioning", "deprovisioning", "updating"];

    const loadPsonoSaasInstanceList = () => {
        psonoSaasInstanceService.getPsonoSaasInstances(project.id).then(
            (psonoSaasInstances) => {
                setPsonoSaasInstances(psonoSaasInstances.map((psonoSaasInstance) => [psonoSaasInstance.id, psonoSaasInstance.name, psonoSaasInstance.state]))
            },
            (response) => {
                console.log(response);
            }
        );
    }
    React.useEffect(() => {
        loadPsonoSaasInstanceList();
    }, [project]);

    const onCreate = () => {
        history.push("/psono-saas-instance/create/");
    };
    const onDelete = (rowData) => {
        psonoSaasInstanceService.deletePsonoSaasInstance(rowData[0]).then(
            () => {
                loadPsonoSaasInstanceList();
            },
            (response) => {
                console.log(response);
            }
        );
    };
    const onEdit = (rowData) => {
        history.push("/psono-saas-instance/detail/" + rowData[0] + "/");
    };


    const columns = [
        { name: t("ID"), options: { display: false } },
        { name: t("NAME") },
        {
            name: t("STATUS"),
            options: {
                filter: true,
                sort: false,
                empty: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const instanceState = tableMeta.rowData[2];
                    return (
                        <>
                            {instanceState}{" "}
                            {waitingStates.includes(tableMeta.rowData[2]) && <CircularProgress size={12} className={classes.waitingIndicator} />}
                        </>
                    );
                },
            },
        },
        {
            name: t("EDIT"),
            options: {
                filter: true,
                sort: false,
                empty: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <IconButton
                            disabled={!editableStates.includes(tableMeta.rowData[2])}
                            onClick={() => {
                                onEdit(tableMeta.rowData);
                            }}
                            size="large">
                            <EditIcon />
                        </IconButton>
                    );
                },
            },
        },
        {
            name: t("DELETE"),
            options: {
                filter: true,
                sort: false,
                empty: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <IconButton
                            disabled={!editableStates.includes(tableMeta.rowData[2]) || !project.permissions.includes('update')}
                            onClick={() => {
                                onDelete(tableMeta.rowData);
                            }}
                            size="large">
                            <DeleteIcon />
                        </IconButton>
                    );
                },
            },
        },
    ];

    const options = {
        filterType: "checkbox",
    };

    return <Table data={psonoSaasInstances} columns={columns} options={options} onCreate={project.permissions.includes('update') ? onCreate : null} />;

}

PsonoSaasListTable.propTypes = {
    project: PropTypes.object.isRequired,
};

export default PsonoSaasListTable;
