import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import PeopleIcon from "@mui/icons-material/People";
import RadioButtonUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import EditIcon from "@mui/icons-material/Edit";

import projectService from "../../services/projects";
import Table from "../../container/table";
import ProjectCreateDialog from "./projectCreateDialog";
import ProjectEditDialog from "./projectEditDialog";


function ProjectListTable(props) {

    const { t } = useTranslation();
    const history = useHistory();
    const [projectCreateDialogOpen, setProjectCreateDialogOpen] = useState(false);
    const [projectEditDialogOpen, setProjectEditDialogOpen] = useState(false);
    const [projectId, setProjectId] = useState("");
    const [projectIndex, setProjectIndex] = useState([]);
    const [projectColumns, setProjectColumns] = useState([]);
    const loadProjects= () => {
        projectService.getProjects().then(
            (projects) => {
                const projectIndex = {};
                projects.map((project) => (projectIndex[project.id] = project));
                setProjectIndex(projectIndex);
                setProjectColumns(projects.map((project) => [project.id, project.name, project.permissions]))
            },
            (response) => {
                console.log(response);
            }
        );
    }

    React.useEffect(() => {
        loadProjects();
    }, []);

    const handleClose = () => {
        setProjectCreateDialogOpen(false)
        setProjectEditDialogOpen(false)
    };

    const onProjectCreate = (result) => {
        loadProjects();
        handleClose();
    };

    const onProjectEdit = (result) => {
        loadProjects();
        handleClose();
    };

    const onUserEdit = (result) => {
        handleClose();
    };

    const handleOpenCreate = () => {
        setProjectCreateDialogOpen(true);
    };

    const handleOpenEdit = (rowData) => {
        setProjectEditDialogOpen(true)
        setProjectId(rowData[0])
    };

    const onSelect = (rowData) => {
        if (projectIndex.hasOwnProperty(rowData[0])) {
            props.onProjectSelect(projectIndex[rowData[0]]);
        }
    };
    const onUsers = (rowData) => {
        if (projectIndex.hasOwnProperty(rowData[0])) {
            props.onProjectSelect(projectIndex[rowData[0]]);
        }
        history.push("/projects/permissions/" + rowData[0] + "/");
    };

    const columns = [
        { name: t("ID"), options: { display: false } },
        { name: t("NAME") },
        {
            name: t("SELECT"),
            options: {
                filter: true,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <IconButton
                            onClick={() => {
                                onSelect(tableMeta.rowData);
                            }}
                            size="large">
                            <RadioButtonUnchecked />
                        </IconButton>
                    );
                },
            },
        },
        {
            name: t("EDIT"),
            options: {
                filter: true,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <IconButton
                            onClick={() => {
                                handleOpenEdit(tableMeta.rowData);
                            }}
                            size="large">
                            <EditIcon />
                        </IconButton>
                    );
                },
            },
        },
        {
            name: t("USERS"),
            options: {
                filter: true,
                sort: false,
                empty: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <IconButton
                            onClick={() => {
                                onUsers(tableMeta.rowData);
                            }}
                            disabled={tableMeta.rowData[4].indexOf("grant") === -1}
                            size="large">
                            <PeopleIcon />
                        </IconButton>
                    );
                },
            },
        },
    ];

    const options = {
        filterType: "checkbox",
    };

    return (
        <div>
            <Table
                data={projectColumns}
                columns={columns}
                options={options}
                onCreate={handleOpenCreate}
            />
            {projectCreateDialogOpen && (
                <ProjectCreateDialog open={projectCreateDialogOpen} onClose={handleClose} onProjectCreate={onProjectCreate} />
            )}
            {projectEditDialogOpen && projectId && (
                <ProjectEditDialog open={projectEditDialogOpen} onClose={handleClose} onProjectEdit={onProjectEdit} projectId={projectId} />
            )}
        </div>
    );

}

ProjectListTable.propTypes = {
    onProjectSelect: PropTypes.func.isRequired,
};

export default ProjectListTable;
