import React  from "react";
import {Helmet} from "react-helmet";
import { useTranslation } from "react-i18next";

import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


import logo from "../assets/img/logo.png";

import Copyright from "../components/copyright";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.up("sm")]: {
            padding: theme.spacing(2),
            borderWidth: "1px",
            borderColor: "#DADCE0FF",
            borderStyle: "solid",
            borderRadius: "8px",
        },
    },
    logo: {
        margin: theme.spacing(1),
    },
    bold: {
        fontWeight: "bold"
    },
    maincontent: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(4),
    },
}));

function SubprocessorView(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Container component="main" maxWidth="md">
            <Helmet>
                <title>{t("Subprocessor")}</title>
            </Helmet>
            <CssBaseline />
            <div className={classes.paper}>
                <img src={logo} alt="logo" className={classes.logo} />
                <Typography component="h1" variant="h5">
                    {t("Subprocessor")}
                </Typography>
                <div className={classes.maincontent}>
                    <Typography component="p">
                        Europe's GDPR, and similar regulations worldwide require companies that
                        "process" "personal data" (as those or similar terms) to take certain steps to protect this data and the privacy of those to whom it relates. One requirement
                        is to disclose certain information about any entity the company may share personal data within
                        the course of providing services and conducting its business. esaqa’s list of these Subprocessors is below.
                    </Typography>

                    <Typography component="h2" variant="h5" style={{ marginTop: "20px", marginBottom: "20px" }}>
                        List of Subprocessors
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Location</TableCell>
                            <TableCell>Service Type</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Google Cloud EMEA Limited
                          </TableCell>
                          <TableCell>Ireland</TableCell>
                          <TableCell>Hosting which includes e.g. servers, databases, network, ...</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Cloudflare
                          </TableCell>
                          <TableCell>United States</TableCell>
                          <TableCell>DDoS protection, CDN and DNS</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Brevo - Sendinblue
                          </TableCell>
                          <TableCell>France</TableCell>
                          <TableCell>Email Service for transactional emails (e.g. registation, new share, invoice) and email marketing</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Sentry Inc.
                          </TableCell>
                          <TableCell>United States</TableCell>
                          <TableCell>Error reporting</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Freshworks Inc.
                          </TableCell>
                          <TableCell>United States</TableCell>
                          <TableCell>Ticketing to handle all requests of Customer.</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Stripe Inc.
                          </TableCell>
                          <TableCell>United States</TableCell>
                          <TableCell>Creditcard payments.</TableCell>
                        </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>


                    <Typography style={{ marginTop: "20px", marginBottom: "20px" }}>
                        Last updated December 17th 2022
                    </Typography>
                </div>
            </div>
            <Box mt={2}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default SubprocessorView;
