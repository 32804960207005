import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import Alert from '@mui/material/Alert';
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import projectPermissionUserService from "../../../services/project-permission-users";
import projectInviteUserService from "../../../services/project-invite-users";
import Table from "../../../container/table";


function ProjectUserListTable(props) {

    const { t } = useTranslation();
    const history = useHistory();
    const [projectPermissionUsers, setProjectPermissionUsers] = useState([]);
    const [errors, setErrors] = useState([]);

    const loadProjectUsers = () => {
        projectPermissionUserService.getProjectPermissionUsers(props.projectId).then(
            (projectPermissionUsers) => {
                projectInviteUserService.getProjectInviteUsers(props.projectId).then(
                    (projectInviteUsers) => {
                        setProjectPermissionUsers(projectPermissionUsers
                            .map((projectPermissionUser) => [
                                projectPermissionUser.id,
                                projectPermissionUser.user_email,
                                projectPermissionUser.role,
                                false, // pending state
                                projectPermissionUser.own_permission,
                            ])
                            .concat(
                                projectInviteUsers.map((projectInviteUser) => [
                                    projectInviteUser.id,
                                    projectInviteUser.email,
                                    projectInviteUser.role,
                                    true, // pending state
                                    false,
                                ])
                            ))
                    },
                    (response) => {
                        console.log(response);
                    }
                );
            },
            (response) => {
                console.log(response);
            }
        );
    }

    React.useEffect(() => {
        loadProjectUsers();
    }, []);
    const onCreate = () => {
        history.push("/projects/permissions/create/" + props.projectId + "/");
    };
    const onDeletePermission = (rowData) => {
        projectPermissionUserService.deleteProjectPermissionUser(rowData[0]).then(
            () => {
                loadProjectUsers();
            },
            (response) => {
                console.log(response);
            }
        );
    };
    const onDeleteInvite = (rowData) => {
        projectInviteUserService.deleteProjectInviteUser(rowData[0]).then(
            () => {
                loadProjectUsers();
            },
            (response) => {
                console.log(response);
            }
        );
    };
    const onEdit = (rowData) => {
        history.push("/projects/permissions/detail/" + rowData[0] + "/");
    };

    const columns = [
        { name: t("ID"), options: { display: false } },
        { name: t("EMAIL") },
        { name: t("ROLE") },
        {
            name: t("STATUS"),
            options: {
                filter: true,
                sort: false,
                empty: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (tableMeta.rowData[3]) {
                        return t("PENDING");
                    } else {
                        return t("ACTIVE");
                    }
                },
            },
        },
        {
            name: t("EDIT"),
            options: {
                filter: true,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <IconButton
                            onClick={() => {
                                onEdit(tableMeta.rowData);
                            }}
                            size="large">
                            <EditIcon />
                        </IconButton>
                    );
                },
            },
        },
        {
            name: t("DELETE"),
            options: {
                filter: true,
                sort: false,
                empty: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <IconButton
                            onClick={() => {
                                if (!tableMeta.rowData[3]) {
                                    onDeletePermission(tableMeta.rowData);
                                } else {
                                    onDeleteInvite(tableMeta.rowData);
                                }
                            }}
                            disabled={tableMeta.rowData[5]}
                            size="large">
                            <DeleteIcon />
                        </IconButton>
                    );
                },
            },
        },
    ];

    const options = {
        filterType: "checkbox",
    };

    return (
        <>
            <Table data={projectPermissionUsers} columns={columns} options={options} onCreate={onCreate} />
            {errors.length > 0 && (
                <Alert variant="outlined" severity="error">
                    {errors.map((error) => t(error))}
                </Alert>
            )}
        </>
    );

}

ProjectUserListTable.propTypes = {
    projectId: PropTypes.string.isRequired,
};

export default ProjectUserListTable;
