import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import psonoLicenseService from "../../services/psono-licenses";
import Table from "../../container/table";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";


function PsonoLicenseTable({project}) {

    const history = useHistory();
    const { t } = useTranslation();
    const [psonoLicenses, setPsonoLicenses] = useState([]);

    const loadPsonoLicenseList = () => {
        psonoLicenseService.getPsonoLicenses(project.id).then(
            (psonoLicenses) => {
                setPsonoLicenses(psonoLicenses.map((psonoLicense) => [psonoLicense.id, psonoLicense.name, psonoLicense.state, psonoLicense.auto_renew]))
            },
            (response) => {
                console.log(response);
            }
        );
    }

    React.useEffect(() => {
        loadPsonoLicenseList();
    }, [project]);

    const onCreate = () => {
        history.push("/psono-license/create/");
    };
    const onEdit = (rowData) => {
        history.push("/psono-license/detail/" + rowData[0] + "/");
    };

    const columns = [
        { name: t("ID"), options: { display: false } },
        { name: t("NAME") },
        {
            name: t("STATUS"),
        },
        {
            name: t("AUTO_RENEW"),
            options: {
                filter: true,
                sort: true,
                empty: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <span>{tableMeta.rowData[3] && <CheckIcon />}</span>;
                },
            },
        },
        {
            name: t("EDIT"),
            options: {
                filter: true,
                sort: false,
                empty: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <IconButton
                            onClick={() => {
                                onEdit(tableMeta.rowData);
                            }}
                            size="large">
                            <EditIcon />
                        </IconButton>
                    );
                },
            },
        },
    ];

    const options = {
        filterType: "checkbox",
    };

    return <Table data={psonoLicenses} columns={columns} options={options} onCreate={project.permissions.includes('update') ? onCreate : null} />;
}

PsonoLicenseTable.propTypes = {
    project: PropTypes.object.isRequired,
};

export default PsonoLicenseTable;
