import React from "react";

import { Route, Switch } from "react-router-dom";
import ProjectUserList from "./projectUserList";
import ProjectUserCreate from "./projectUserCreate";
import ProjectUserDetail from "./projectUserDetail";

function Index(props) {
    return (
        <Switch>
            <Route path="/projects/permissions/create/:projectId/">
                <ProjectUserCreate />
            </Route>
            <Route path="/projects/permissions/detail/:projectPermissionUserId/">
                <ProjectUserDetail />
            </Route>
            <Route path="/projects/permissions/:projectId/">
                <ProjectUserList />
            </Route>
        </Switch>
    );
}
export default Index;
