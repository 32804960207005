import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import makeStyles from '@mui/styles/makeStyles';

import Typography from "@mui/material/Typography";
import Alert from '@mui/material/Alert';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import billingAccountInviteUsersService from "../../services/billing-account-invite-users";
import BillingAccountRoleSelectField from "../../components/billing-account-role-select-field";
import ContentBody from "../../container/content-body";
import ContentHeader from "../../container/content-header";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "inline",
    },
    h2: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    buttons: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    backButton: {
        marginTop: "-12px",
        marginBottom: "-12px",
        marginLeft: "-12px",
    },
    textField: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "440px",
        },
    },
}));

function BillingAccountInvite(props) {

    const history = useHistory();
    const classes = useStyles();
    const params = useParams();
    const { t } = useTranslation();
    const [error, setError] = useState("");
    const [role, setRole] = useState("");
    const [billingAccountName, setBillingAccountName] = useState("");

    const getUsers = () => {
        billingAccountInviteUsersService.getBillingAccountInviteUsers(null, params.billingAccountInviteId).then(
            (billingAccountInvite) => {
                setRole(billingAccountInvite.role)
                setBillingAccountName(billingAccountInvite.billing_account.name)
            },
            (response) => {
                console.log(response);
                setError("INVITE_DOES_NOT_EXIST_OR_HAS_EXPIRED")
            }
        );
    }
    React.useEffect(() => {
        getUsers();
    }, []);

    const onAccept = () => {
        billingAccountInviteUsersService.acceptBillingAccountInviteUsers(params.billingAccountInviteId, params.billingAccountInviteSecret).then(
            (response) => {
                history.push("/billing-accounts/");
            },
            (errors) => {}
        );
    };

    const onReject = () => {
        billingAccountInviteUsersService.rejectBillingAccountInviteUsers(params.billingAccountInviteId, params.billingAccountInviteSecret).then(
            (response) => {
                history.push("/billing-accounts/");
            },
            (errors) => {}
        );
    };

    return <>
        <ContentHeader>
            <Typography component="h1" variant="h5" className={classes.title}>
                <IconButton
                    onClick={() => {
                        history.goBack();
                    }}
                    color="primary"
                    aria-label="go back"
                    className={classes.backButton}
                    size="large">
                    <ArrowBackIcon />
                </IconButton>
                {t("BILLING_ACCOUNT_INVITE")}
            </Typography>
        </ContentHeader>
        {error ? (
            <ContentBody>
                <Alert variant="outlined" severity="error" className={classes.alertError}>
                    {t(error)}
                </Alert>
            </ContentBody>
        ) : (
            <ContentBody>
                <Grid container>
                    <Grid item xs={12}>
                        <Alert variant="outlined" severity="info">
                            {t("BILLING_ACCOUNT_INVITE_DETAILS")}
                        </Alert>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.textField}
                            variant="outlined"
                            margin="dense" size="small"
                            id="billingAccountName"
                            label={t("BILLING_ACCOUNT_NAME")}
                            name="billingAccountName"
                            autoComplete="billingAccountName"
                            value={billingAccountName}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <BillingAccountRoleSelectField
                            className={classes.textField}
                            variant="outlined"
                            margin="dense" size="small"
                            required
                            id="role"
                            value={role}
                            disabled={true}
                        />
                    </Grid>
                </Grid>

                <div className={classes.buttons}>
                    <Button variant="contained" color="primary" onClick={onAccept}>
                        {t("ACCEPT")}
                    </Button>
                    <Button variant="contained" onClick={onReject}>
                        {t("REJECT")}
                    </Button>
                </div>
            </ContentBody>
        )}
    </>;

}
export default BillingAccountInvite;
