import React, {useState, useEffect, useCallback, useRef} from "react";
import {FixedSizeList as List} from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import TextField from '@mui/material/TextField';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Backdrop from "@mui/material/Backdrop";
import DownloadIcon from '@mui/icons-material/Download';
import { saveAs } from 'file-saver';

import { subHours } from 'date-fns';
import { format, parseISO } from 'date-fns';

import ContentBody from "../../container/content-body";
import ContentHeader from "../../container/content-header";
import PsonoSaasLogService from "../../services/psono-saas-log";
import Button from "@mui/material/Button";


const useStyles = makeStyles((theme) => ({
    title: {
        display: "inline",
    },
    h2: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    buttons: {
        marginTop: theme.spacing(2),
    },
    infoBox: {
        paddingTop: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            paddingTop: "0",
        },
    },
    backButton: {
        marginTop: "-12px",
        marginBottom: "-12px",
        marginLeft: "-12px",
    },
    searchButtonWrap: {
        paddingLeft: "4px",
        paddingTop: "9px",
        paddingBottom: "9px",
    },
    deleteButton: {
        marginLeft: theme.spacing(1),
    },
    textField: {
        width: "100%"
    },
    iconButton: {},
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    datePicker: {
        width: "100%"
    },
    titleText: {
        marginRight: theme.spacing(1),
    },
}));

function PsonoSaasLog(props) {
    const classes = useStyles();
    const {t} = useTranslation();
    const [startTime, setStartTime] = useState(subHours(new Date(), 1));
    const [endTime, setEndTime] = useState(new Date());
    const [query, setQuery] = useState('');
    const history = useHistory();
    const params = useParams();

    const [logs, setLogs] = useState(null)
    const listRef = useRef(null);

    const fetchLogs = () => {
        PsonoSaasLogService.getPsonoSaasLog(params.id, startTime, endTime, query).then(
            (newLogs) => {
                setLogs(prevLogs => {
                    let updatedLogs = [];
                    if (prevLogs !== null) {
                        updatedLogs = [...prevLogs, ...newLogs]
                    } else {
                        updatedLogs = [...newLogs]
                    }
                    // Optionally truncate logs
                    if (updatedLogs.length > 10000) {
                        return updatedLogs.slice(updatedLogs.length - 10000);
                    }
                    return updatedLogs;
                });
            },
            (errors) => {
                console.log(errors)
            }
        );
    };
    // Fetch logs periodically
    useEffect(() => {
        fetchLogs();
        // const intervalId = setInterval(fetchLogs, 10000); // Fetch every 10 seconds
        //
        // return () => clearInterval(intervalId); // Clean up interval on unmount
    }, []);

    useEffect(() => {
        if (listRef.current && logs !== null) {
            listRef.current.scrollToItem(logs.length - 1);
        }
    }, [logs]);

    const onSearch = () => {
        setLogs(null);
        fetchLogs();
    }
    const handleExport = () => {
        const blob = new Blob([logs.map((log) => log.timestamp + ' ' + log.payload).join('\n')], { type: 'text/plain;charset=utf-8' });
        saveAs(blob, 'logs.txt');
    };

    const Row = useCallback(
        ({index, style}) => (
            <div
                style={{
                    ...style,
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    lineHeight: '20px',
                    height: '20px',
                }}
            >
                {format(parseISO(logs[index].timestamp), "MM/dd/yyyy hh:mm a")} {logs[index].payload}
            </div>
        ),
        [logs]
    );

    return <>
        <Backdrop className={classes.backdrop} open={logs===null}>
            <CircularProgress color="inherit"/>
        </Backdrop>
        <ContentHeader>
    <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
            <Typography component="h1" variant="h5" className={classes.title}>
                <IconButton
                    onClick={() => {
                        history.goBack();
                    }}
                    color="primary"
                    aria-label="go back"
                    className={classes.backButton}
                    size="large">
                    <ArrowBackIcon/>
                </IconButton>

                <span className={classes.titleText}>{t("LOGS")}</span>
            </Typography>
        </Grid>
        <Grid item>
            <Button
                startIcon={<DownloadIcon />}
                onClick={handleExport}
                className={classes.exportButton}
            >
                {t("EXPORT")}
            </Button>
        </Grid>
    </Grid>
</ContentHeader>
        <ContentBody>
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12} md={6} xl={7} style={{ flexGrow: 1, marginTop: '18px', paddingTop: '0' }}>
                    <TextField
                        label={t("SEARCH")}
                        variant="outlined"
                        fullWidth
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        error={Boolean(query) && (query.includes("(") || query.includes(")"))}
                        helperText={Boolean(query) && (query.includes("(") || query.includes(")")) ? t("SEARCH_MAY_NOT_CONTAIN_BRACKETS") : t("USE_OPERATORS_TO_REFINE_SEARCH")}
                    />
                </Grid>
                <Grid item xs={12} md={6} xl={5} justifyContent="flex-end"  style={{ paddingTop: '0' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={4} md={4} xl={3}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    label="Start Date"
                                    value={startTime}
                                    onChange={(newValue) => setStartTime(newValue)}
                                    renderInput={(params) => <TextField {...params} fullWidth/>}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={4} md={4} xl={3}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    label="End Date"
                                    value={endTime}
                                    onChange={(newValue) => setEndTime(newValue)}
                                    renderInput={(params) => <TextField {...params} fullWidth/>}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={4} md={4} xl={6}>
                            <div className={classes.searchButtonWrap}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={onSearch}
                                    fullWidth
                                    disabled={Boolean(query) && (query.includes("(") || query.includes(")"))}
                                >
                                    {t("SEARCH")}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div style={{width: '100%', height: 'calc(100vh - 260px)'}}>
                        <AutoSizer>
                            {({height, width}) => (
                                <List
                                    height={height}
                                    itemCount={logs !== null ? logs.length : 0}
                                    itemSize={20}
                                    width={width}
                                    ref={listRef}
                                >
                                    {Row}
                                </List>
                            )}
                        </AutoSizer>
                    </div>
                </Grid>
            </Grid>

        </ContentBody>
    </>;

}

PsonoSaasLog.propTypes = {
    project: PropTypes.object.isRequired,
};

export default PsonoSaasLog;
