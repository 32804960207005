import React from "react";

import { Route, Switch } from "react-router-dom";
import PsonoLicenseList from "./psonoLicenseList";
import PsonoLicenseCreate from "./psonoLicenseCreate";
import PsonoLicenseDetail from "./psonoLicenseDetail";
import PropTypes from "prop-types";


function Index(props) {
    const { project } = props;
    return (
        <Switch>
            <Route path="/psono-license/create/">
                <PsonoLicenseCreate project={project} />
            </Route>
            <Route path="/psono-license/detail/:id/">
                <PsonoLicenseDetail project={project} />
            </Route>
            <Route path="/psono-license/">
                <PsonoLicenseList project={project} />
            </Route>
        </Switch>
    );
}
Index.propTypes = {
    project: PropTypes.object.isRequired,
};

export default Index;
