import React, { Suspense } from "react";

// initially required for stripe
import "core-js/stable";
import "regenerator-runtime/runtime";
import { I18nextProvider } from "react-i18next";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { render } from "react-dom";

import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers'

import i18n from "./i18n";
import theme from "./theme";
import SignInView from "./views/signin";
import TermsAndConditionsView from "./views/terms-and-conditions";
import LegalNoticeView from "./views/legal-notice";
import PrivacyPolicyView from "./views/privacy-policy";
import SubprocessorView from "./views/subprocessor";
import DPAView from "./views/dpa";
import TOMView from "./views/tom";
import IndexView from "./views/index";

const customHistory = createBrowserHistory();

function App(props) {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <I18nextProvider i18n={i18n}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <Router history={customHistory}>
                            <div>
                                <Switch>
                                    <Route path="/signin/">
                                        <SignInView />
                                    </Route>
                                    <Route path="/terms-and-conditions/">
                                        <TermsAndConditionsView />
                                    </Route>
                                    <Route path="/legal-notice/">
                                        <LegalNoticeView />
                                    </Route>
                                    <Route path="/privacy-policy/">
                                        <PrivacyPolicyView />
                                    </Route>
                                    <Route path="/subprocessor/">
                                        <SubprocessorView />
                                    </Route>
                                    <Route path="/dpa/">
                                        <DPAView />
                                    </Route>
                                    <Route path="/tom/">
                                        <TOMView />
                                    </Route>
                                    <Route path="/">
                                        <IndexView />
                                    </Route>
                                </Switch>
                            </div>
                        </Router>
                    </ThemeProvider>
                </StyledEngineProvider>
            </I18nextProvider>
        </LocalizationProvider>
    );

}

export default App;

const container = document.getElementById("app");
render(
    <Suspense fallback="loading...">
        <App />
    </Suspense>,
    container
);
