import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";

import PsonoSaasListTable from "./psonoLicenseTable";
import ContentBody from "../../container/content-body";
import ContentHeader from "../../container/content-header";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "inline",
    },
}));

function PsonoLicenseList({project}) {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <>
            <ContentHeader>
                <Typography component="h1" variant="h5" className={classes.title}>
                    {t("PSONO_LICENSES")}
                </Typography>
            </ContentHeader>
            <ContentBody>
                <PsonoSaasListTable project={project} />
            </ContentBody>
        </>
    );
}

PsonoSaasListTable.propTypes = {
    project: PropTypes.object.isRequired,
};

export default PsonoLicenseList;
