import apiServer from "./api-server";

/**
 * Returns all billing account invites for a certain billing account or a certain specific billing account invite
 *
 * @param projectId
 * @param projectInviteUserId
 *
 * @returns {Promise<Array>}
 */
function getProjectInviteUsers(projectId, projectInviteUserId) {
    return new Promise((resolve, reject) => {
        return apiServer.readProjectInviteUsers(projectId, projectInviteUserId).then(
            (response) => {
                if (projectId) {
                    return resolve(response.data.project_invite_users);
                } else {
                    return resolve(response.data.project_invite);
                }
            },
            (response) => {
                reject(response);
            }
        );
    });
}

/**
 *
 * Creates a billing account invite
 *
 * @param projectId
 * @param email
 * @param projectRoleId
 *
 * @returns {Promise<unknown>}
 */
function createProjectInviteUser(projectId, email, projectRoleId) {
    return new Promise((resolve, reject) => {
        return apiServer.createProjectInviteUser(projectId, email, projectRoleId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 *
 * Accepts a billing account invite
 *
 * @param projectInviteId
 * @param projectInviteSecret
 *
 * @returns {Promise<unknown>}
 */
function acceptProjectInviteUsers(projectInviteId, projectInviteSecret) {
    return new Promise((resolve, reject) => {
        return apiServer.acceptProjectInviteUsers(projectInviteId, projectInviteSecret).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 *
 * Rejects a billing account invite
 *
 * @param projectInviteId
 * @param projectInviteSecret
 *
 * @returns {Promise<unknown>}
 */
function rejectProjectInviteUsers(projectInviteId, projectInviteSecret) {
    return new Promise((resolve, reject) => {
        return apiServer.rejectProjectInviteUsers(projectInviteId, projectInviteSecret).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 *
 * Deletes a billing account invite
 *
 * @param projectInviteUserId
 *
 * @returns {Promise<unknown>}
 */
function deleteProjectInviteUser(projectInviteUserId) {
    return new Promise((resolve, reject) => {
        return apiServer.deleteProjectInviteUser(projectInviteUserId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

const service = {
    getProjectInviteUsers,
    deleteProjectInviteUser,
    createProjectInviteUser,
    acceptProjectInviteUsers,
    rejectProjectInviteUsers,
};

export default service;
