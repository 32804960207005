import React from "react";

import { Route, Switch } from "react-router-dom";
import BillingAccountUserList from "./billingAccountUserList";
import BillingAccountUserCreate from "./billingAccountUserCreate";
import BillingAccountUserDetail from "./billingAccountUserDetail";

function Index(props) {
    return (
        <Switch>
            <Route path="/billing-accounts/permissions/create/:billingAccountId/">
                <BillingAccountUserCreate />
            </Route>
            <Route path="/billing-accounts/permissions/detail/:billingAccountPermissionUserId/">
                <BillingAccountUserDetail />
            </Route>
            <Route path="/billing-accounts/permissions/:billingAccountId/">
                <BillingAccountUserList />
            </Route>
        </Switch>
    );
}
export default Index;
