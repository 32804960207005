import React, { useState, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import makeStyles from '@mui/styles/makeStyles';
import {useTranslation} from "react-i18next";

import ProjectButton from "./projectButton";
import PropTypes from "prop-types";
import CSRFToken from "./csrftoken";

const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        marginRight: "20px",
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
}));

function NavigationBar(props) {
    const logoutForm = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();
    const { t } = useTranslation();

    const { onProjectSelect } = props;

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const signOut = () => {
        //window.location.href = "/oidc/logout/";
        logoutForm.current.submit();
    };

    const account = () => {
        window.open("/account/", "_blank");
    };

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Typography component="div" variant="h6" className={classes.title}>
                        esaqa console
                    </Typography>
                    <ProjectButton onProjectSelect={onProjectSelect}/>
                    <div>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                            size="large">
                            <AccountCircle/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={account}>{t("ACCOUNT")}</MenuItem>
                            <MenuItem onClick={signOut}>{t("SIGN_OUT")}</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
                <form ref={logoutForm} noValidate method="post" action="/oidc/logout/">
                    <CSRFToken/>
                </form>
            </AppBar>
        </div>
    );

}

NavigationBar.propTypes = {
    onProjectSelect: PropTypes.func.isRequired,
};

export default NavigationBar;
