import React from "react";

import { Route, Switch } from "react-router-dom";
import PsonoSaasInstanceList from "./psonoSaasInstanceList";
import PsonoSaasInstanceCreate from "./psonoSaasInstanceCreate";
import PsonoSaasInstanceDetail from "./psonoSaasInstanceDetail";
import PsonoSaasCustomDomain from "./psonoSaasCustomDomain";
import PsonoSaasLogs from "./psonoSaasLogs";
import PropTypes from "prop-types";

function Index(props) {
    const { project } = props;
    return (
        <Switch>
            <Route path="/psono-saas-instance/create/">
                <PsonoSaasInstanceCreate project={project} />
            </Route>
            <Route path="/psono-saas-instance/detail/:id/custom-domain/">
                <PsonoSaasCustomDomain project={project} />
            </Route>
            <Route path="/psono-saas-instance/detail/:id/logs/">
                <PsonoSaasLogs project={project} />
            </Route>
            <Route path="/psono-saas-instance/detail/:id/">
                <PsonoSaasInstanceDetail project={project} />
            </Route>
            <Route path="/psono-saas-instance/">
                <PsonoSaasInstanceList project={project} />
            </Route>
        </Switch>
    );
}
Index.propTypes = {
    project: PropTypes.object.isRequired,
};

export default Index;
