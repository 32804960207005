import apiServer from "./api-server";

let cachedProjects = [];
let selectedProject;

/**
 * GET: Returns all projects
 *
 * @returns {Promise<Array>}
 */
function getProjects() {
    return new Promise((resolve, reject) => {
        if (cachedProjects && cachedProjects.length) {
            return resolve(cachedProjects);
        } else {
            return apiServer.readProjects().then(
                (response) => {
                    cachedProjects = response.data.projects;
                    return resolve(cachedProjects);
                },
                (response) => {
                    console.log("UNEXPECTED_ERROR_63522d26-9aee-4fe1-adbb-270de7c6ab99");
                    reject(response);
                }
            );
        }
    });
}

/**
 * GET: Returns a single project by its id
 *
 * @returns {Promise<unknown>}
 */
function getProject(projectId) {
    return new Promise((resolve, reject) => {
        return apiServer.readProjects(projectId).then(
            (response) => {
                return resolve(response.data);
            },
            (response) => {
                console.log("UNEXPECTED_ERROR_a57ebf78-06f8-439f-9851-2daa761a6c38");
                reject(response);
            }
        );
    });
}

/**
 * GET: Returns active projects
 *
 * @returns {Promise<unknown>}
 */
function getActiveProject() {
    return new Promise((resolve, reject) => {
        if (selectedProject && cachedProjects && cachedProjects.length) {
            return resolve({ projects: cachedProjects, selectedProject: selectedProject });
        } else if (cachedProjects && cachedProjects.length) {
            selectedProject = cachedProjects[0];
            return resolve({ projects: cachedProjects, selectedProject: selectedProject });
        } else {
            return getProjects().then((projects) => {
                if (projects.length) {
                    selectedProject = cachedProjects[0];
                    return resolve({ projects: cachedProjects, selectedProject: selectedProject });
                } else {
                    return resolve({ projects: [], selectedProject: null });
                }
            });
        }
    });
}

/**
 *
 * Creates a project
 *
 * @param name
 * @param billingAccountId
 *
 * @returns {Promise<unknown>}
 */
function createProject(name, billingAccountId) {
    return new Promise((resolve, reject) => {
        return apiServer.createProject(name, billingAccountId).then(
            (response) => {
                cachedProjects = [];
                selectedProject = response.data;
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 *
 * Updates a project
 *
 * @param projectId
 * @param name
 * @param billingAccountId
 *
 * @returns {Promise<unknown>}
 */
function updateProject(projectId, name, billingAccountId) {
    return new Promise((resolve, reject) => {
        return apiServer.updateProject(projectId, name, billingAccountId).then(
            (response) => {
                cachedProjects = [];
                selectedProject = response.data;
                return resolve(response.data);
            },
            (response) => {
                reject(response.data);
            }
        );
    });
}

/**
 * Marks another project as selected
 *
 * @param newSelectedProject
 *
 * @returns {Promise<unknown>}
 */
function selectProject(newSelectedProject) {
    selectedProject = newSelectedProject;
}

const service = {
    getProjects,
    getProject,
    updateProject,
    getActiveProject,
    createProject,
    selectProject,
};

export default service;
