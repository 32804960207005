import React, { useState } from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";

import Typography from "@mui/material/Typography";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import makeStyles from '@mui/styles/makeStyles';

import ContentBody from "../../../container/content-body";
import ContentHeader from "../../../container/content-header";
import InjectedStripeCardSetupForm from "./injected-stripe-card-setup-form";
import stripeService from "../../../services/stripe";
import billingAccountService from "../../../services/billing-accounts";
import paymentMethodService from "../../../services/payment-methods";


const useStyles = makeStyles((theme) => ({
    title: {
        display: "inline",
    },
    h2: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    buttons: {
        marginTop: theme.spacing(2),
    },
    backButton: {
        marginTop: "-12px",
        marginBottom: "-12px",
        marginLeft: "-12px",
    },
    textField: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "440px",
        },
    },
}));

function PaymentMethodStripeCreate(props) {

    const history = useHistory();
    const params = useParams();
    const classes = useStyles();
    const { t } = useTranslation();
    const [stripePublishableKey, setStripePublishableKey] = useState(null);
    const [stripeIntent, setStripeIntent] = useState(null);
    const [billingDetails, setBillingDetails] = useState(null);
    const [errors, setErrors] = useState([]);

    React.useEffect(() => {
        stripeService.createStripeIntent(params.billingAccountId).then(
            (result) => {
                setStripePublishableKey(result.stripe_publishable_key);
                setStripeIntent(result.stripe_intent);
            },
            (response) => {
                console.log(response);
            }
        );
        billingAccountService.getBillingAccount(params.billingAccountId).then(
            (result) => {
                setBillingDetails({
                    address: {
                        city: result.city || null,
                        country: result.country || null,
                        line1: result.address1 || null,
                        line2: result.address2 || null,
                        postal_code: result.zip_code || null,
                        state: result.state || null,
                    },
                    name: result.company_name,
                })
            },
            (response) => {
                console.log(response);
            }
        );
    }, []);

    const onCreate = (result) => {
        paymentMethodService.createPaymentMethod(params.billingAccountId, 'stripe', result.setupIntent).then(
            (response) => {
                history.push("/payment-methods/");
            },
            (errors) => {
                setErrors(errors)
            }
        );
    };

    let stripePromise;
    if (stripePublishableKey && billingDetails) {
        stripePromise = loadStripe(stripePublishableKey);
    }

    return <>
        <ContentHeader>
            <Typography component="h1" variant="h5" className={classes.title}>
                <IconButton
                    onClick={() => {
                        history.goBack();
                    }}
                    color="primary"
                    aria-label="go back"
                    className={classes.backButton}
                    size="large">
                    <ArrowBackIcon />
                </IconButton>
                {t("CREATE_PAYMENT_METHOD")}
            </Typography>
        </ContentHeader>
        <ContentBody>
            <Grid container>
                <Grid item xs={12}>
                    {stripePromise && stripeIntent && (
                        <Elements stripe={stripePromise}>
                            <InjectedStripeCardSetupForm
                                clientSecret={stripeIntent.client_secret}
                                onCreate={onCreate}
                                billingDetails={billingDetails}
                            />
                        </Elements>
                    )}
                </Grid>
            </Grid>
        </ContentBody>
    </>;

}

export default PaymentMethodStripeCreate;
