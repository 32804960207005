import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import makeStyles from '@mui/styles/makeStyles';

import Typography from "@mui/material/Typography";
import Alert from '@mui/material/Alert';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import projectInviteUsersService from "../../services/project-invite-users";
import ProjectRoleSelectField from "../../components/project-role-select-field";
import ContentBody from "../../container/content-body";
import ContentHeader from "../../container/content-header";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "inline",
    },
    h2: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    buttons: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    backButton: {
        marginTop: "-12px",
        marginBottom: "-12px",
        marginLeft: "-12px",
    },
    textField: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "440px",
        },
    },
}));

function ProjectInvite(props) {

    const history = useHistory();
    const classes = useStyles();
    const params = useParams();
    const { t } = useTranslation();
    const [error, setError] = useState("");
    const [role, setRole] = useState("");
    const [projectName, setProjectName] = useState("");
    
    const getUsers = () => {
        projectInviteUsersService.getProjectInviteUsers(null, params.projectInviteId).then(
            (projectInvite) => {
                setRole(projectInvite.role)
                setProjectName(projectInvite.project.name)
            },
            (response) => {
                console.log(response);
                setError("INVITE_DOES_NOT_EXIST_OR_HAS_EXPIRED")
            }
        );
    }
    React.useEffect(() => {
        getUsers();
    }, []);

    const onAccept = () => {
        projectInviteUsersService.acceptProjectInviteUsers(params.projectInviteId, params.projectInviteSecret).then(
            (response) => {
                history.push("/");
            },
            (errors) => {}
        );
    };

    const onReject = () => {
        projectInviteUsersService.rejectProjectInviteUsers(params.projectInviteId, params.projectInviteSecret).then(
            (response) => {
                history.push("/");
            },
            (errors) => {}
        );
    };

    return <>
        <ContentHeader>
            <Typography component="h1" variant="h5" className={classes.title}>
                <IconButton
                    onClick={() => {
                        history.goBack();
                    }}
                    color="primary"
                    aria-label="go back"
                    className={classes.backButton}
                    size="large">
                    <ArrowBackIcon />
                </IconButton>
                {t("PROJECT_INVITE")}
            </Typography>
        </ContentHeader>
        {error ? (
            <ContentBody>
                <Alert variant="outlined" severity="error" className={classes.alertError}>
                    {t(error)}
                </Alert>
            </ContentBody>
        ) : (
            <ContentBody>
                <Grid container>
                    <Grid item xs={12}>
                        <Alert variant="outlined" severity="info">
                            {t("PROJECT_INVITE_DETAILS")}
                        </Alert>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.textField}
                            variant="outlined"
                            margin="dense" size="small"
                            id="projectName"
                            label={t("PROJECT_NAME")}
                            name="projectName"
                            autoComplete="projectName"
                            value={projectName}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ProjectRoleSelectField
                            className={classes.textField}
                            variant="outlined"
                            margin="dense" size="small"
                            required
                            id="role"
                            value={role}
                            disabled={true}
                        />
                    </Grid>
                </Grid>

                <div className={classes.buttons}>
                    <Button variant="contained" color="primary" onClick={onAccept}>
                        {t("ACCEPT")}
                    </Button>
                    <Button variant="contained" onClick={onReject}>
                        {t("REJECT")}
                    </Button>
                </div>
            </ContentBody>
        )}
    </>;

}
export default ProjectInvite;
