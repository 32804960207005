import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import makeStyles from '@mui/styles/makeStyles';

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Alert from '@mui/material/Alert';
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import ContentBody from "../../container/content-body";
import ContentHeader from "../../container/content-header";
import PsonoLicenseService from "../../services/psono-licenses";
import pricingService from "../../services/pricing";
import format from "../../services/date";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "inline",
    },
    h2: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1),
    },
    buttons: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    backButton: {
        marginTop: "-12px",
        marginBottom: "-12px",
        marginLeft: "-12px",
    },
    textField: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "440px",
        },
    },
    code: {
        fontFamily: "Monospace",
        fontSize: "12.5714px",
    },
}));

function PsonoLicenseDetail({project}) {
    const history = useHistory();
    const classes = useStyles();
    const params = useParams();
    const { t } = useTranslation();

    const [pricing, setPricing] = useState({});
    const [id, setId] = useState("");
    const [licenseCode, setLicenseCode] = useState("");
    const [autoRenew, setAutoRenew] = useState(false);
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [publicKey, setPublicKey] = useState("");
    const [origianlVariation, setOrigianlVariation] = useState("u25");
    const [variation, setVariation] = useState("u25");
    const [validTill, setValidTill] = useState(null);
    const [updatePsonoSaasInstanceSuccess, setUpdatePsonoSaasInstanceSuccess] = useState(false);
    const [errors, setErrors] = useState({});

    const loadLicenseInfo = () => {
        const PsonoSaasId = params.id;
        PsonoLicenseService.getPsonoLicense(PsonoSaasId).then(
            (psonoLicenseDetails) => {
                setId(psonoLicenseDetails.id)
                setLicenseCode(psonoLicenseDetails.license_code)
                setAutoRenew(psonoLicenseDetails.auto_renew)
                setName(psonoLicenseDetails.name)
                setNumber(psonoLicenseDetails.number)
                setPublicKey(psonoLicenseDetails.public_key)
                setValidTill(new Date(psonoLicenseDetails.valid_till))
                setVariation(psonoLicenseDetails.variation)
                setOrigianlVariation(psonoLicenseDetails.variation)
            },
            (response) => {
                console.log(response);
            }
        );
    }
    React.useEffect(() => {
        loadLicenseInfo();
        pricingService.getPricing(project.billing_account_id, project.id, "PSONO_LICENSE").then(
            (pricing) => {
                setPricing(pricing);
            },
            (response) => {
                console.log(response);
            }
        );
    }, []);

    const calculateEstimatedMonthlyPrice = (variation) => {
        const pricingInfo = pricing[variation];
        const hoursPerMonth = (365 * 24) / 12;
        const monthTimeMultiplier = pricingInfo.per === "MINUTE" ? hoursPerMonth * 60 : NaN;
        return Number.parseFloat(pricingInfo.units * pricingInfo.price * monthTimeMultiplier).toFixed(2);
    };

    const onEdit = () => {
        setErrors({})
        const psonoLicenseId = params.id;
        PsonoLicenseService.updatePsonoLicense(psonoLicenseId, name, variation, autoRenew).then(
            (response) => {
                loadLicenseInfo();
                setUpdatePsonoSaasInstanceSuccess(true)
                setTimeout(() => {
                    setUpdatePsonoSaasInstanceSuccess(false)
                }, 5000);
            },
            (errors) => {
                setErrors(errors);
            }
        );
    };

    return <>
        <ContentHeader>
            <Typography component="h1" variant="h5" className={classes.title}>
                <IconButton
                    onClick={() => {
                        history.goBack();
                    }}
                    color="primary"
                    aria-label="go back"
                    className={classes.backButton}
                    size="large">
                    <ArrowBackIcon />
                </IconButton>
                {t("EDIT_PSONO_LICENSE")}
            </Typography>
        </ContentHeader>
        <ContentBody>
            <Grid container>
                <Grid item xs={12} md={8}>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                autoFocus
                                margin="dense" size="small"
                                required
                                id="name"
                                label={t("NAME")}
                                name="name"
                                autoComplete="name"
                                error={errors && "name" in errors}
                                helperText={errors && "name" in errors ? t(errors["name"][0]) : t("PSONO_SAAS_INSTANCE_NAME_EXPLANATION")}
                                value={name}
                                onChange={(event) => {
                                    setName(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                disabled
                                id="publicKey"
                                label={t("PUBLIC_KEY")}
                                name="publicKey"
                                autoComplete="publicKey"
                                error={errors && "public_key" in errors}
                                helperText={errors && "public_key" in errors ? t(errors["public_key"][0]) : t("PSONO_LICENSE_PUBLIC_KEY_EXPLANATION")}
                                value={publicKey}
                                onChange={(event) => {
                                    setPublicKey(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {Object.keys(pricing).length > 0 && (
                                <FormControl
                                    variant="outlined"
                                    className={classes.textField}
                                    margin="dense" size="small"
                                    required
                                    error={errors && "max_users" in errors}
                                >
                                    <InputLabel id="variation-label">{t("MAX_USERS")}</InputLabel>
                                    <Select
                                        labelId="variation-label"
                                        id="variation"
                                        value={variation}
                                        onChange={(event) => {
                                            setVariation(event.target.value)
                                        }}
                                        label={t("MAX_USERS")}
                                    >
                                        {Object.keys(pricing).map((key, index) => {
                                            return (
                                                <MenuItem key={key} value={key}>
                                                    {pricing[key].units} {t("USERS")}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {errors && "max_users" in errors && (
                                        <FormHelperText>{errors && "max_users" in errors ? t(errors["max_users"][0]) : ""}</FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                disabled
                                id="number"
                                label={t("LICENSE_NUMBER")}
                                name="number"
                                autoComplete="number"
                                error={errors && "number" in errors}
                                helperText={errors && "number" in errors ? t(errors["number"][0]) : ""}
                                value={number}
                                onChange={(event) => {
                                    setNumber(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                disabled
                                id="validTill"
                                label={t("VALID_TILL")}
                                name="validTill"
                                autoComplete="validTill"
                                error={errors && "valid_till" in errors}
                                helperText={errors && "valid_till" in errors ? t(errors["valid_till"][0]) : ""}
                                value={validTill ? format(validTill) : ""}
                                onChange={(event) => {
                                    setValidTill(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                disabled
                                multiline
                                InputProps={{
                                    classes: {
                                        input: classes.code,
                                    },
                                }}
                                id="licenseCode"
                                label="LICENSE_CODE"
                                name="licenseCode"
                                autoComplete="licenseCode"
                                error={errors && "license_code" in errors}
                                helperText={errors && "license_code" in errors ? t(errors["license_code"][0]) : t("PSONO_LICENSE_CODE_EXPLAINED")}
                                value={licenseCode}
                                onChange={(event) => {
                                    setLicenseCode(event.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="autoRenew"
                                        checked={autoRenew}
                                        onChange={(e) => {
                                            setAutoRenew(e.target.checked)
                                        }}
                                    />
                                }
                                label={t("AUTOMATIC_YEARLY_RENEWAL")}
                            />
                        </Grid>
                    </Grid>

                    <div className={classes.buttons}>
                        <Button variant="contained" color="primary" onClick={onEdit} disabled={!project.permissions.includes('update')}>
                            {t(validTill < new Date() ? "RENEW" : (origianlVariation !== variation ? "UPGRADE" : "EDIT"))}
                        </Button>
                    </div>
                    {updatePsonoSaasInstanceSuccess && (
                        <Alert variant="outlined" severity="success">
                            {t("SAVE_SUCCESSFUL")}
                        </Alert>
                    )}
                    {origianlVariation !== variation && (
                        <Alert variant="outlined" severity="info">
                            {t("UPGRADE_RENEW_PSONO_LICENSE_BY_ONE_YEAR")}
                        </Alert>
                    )}
                    {validTill < new Date() && (
                        <Alert variant="outlined" severity="info">
                            {t("RENEW_EXPIRED_PSONO_LICENSE_BY_ONE_YEAR")}
                        </Alert>
                    )}
                </Grid>
                {variation && pricing.hasOwnProperty(variation) && (
                    <Grid item xs={12} md={4}>
                        <div className={classes.infoBox}>
                            <Typography component="h2" variant="h6">
                                {t("PER_MONTH_ABOUT")} {pricing[variation].currency_symbol}{calculateEstimatedMonthlyPrice(variation)}
                            </Typography>
                            {t("PSONO_LICENSE_PRICING_INFO")}
                        </div>
                    </Grid>
                )}
            </Grid>
        </ContentBody>
    </>;

}

PsonoLicenseDetail.propTypes = {
    project: PropTypes.object.isRequired,
};

export default PsonoLicenseDetail;
