import React, { useState } from "react";
import AccountTree from "@mui/icons-material/AccountTree";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import {useTranslation} from "react-i18next";
import projectService from "../../services/projects";
import ProjectListDialog from "./projectListDialog";
import ProjectCreateDialog from "./projectCreateDialog";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    button: {
        color: "#ffffff",
        textTransform: "none",
    },
}));

function ProjectButton(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    const [projectListDialogOpen, setProjectListDialogOpen] = useState(false);
    const [projectCreateDialogOpen, setProjectCreateDialogOpen] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [projects, setProjects] = useState([]);

    const loadActiveProject = () => {
        projectService.getActiveProject().then(
            (result) => {
                setProjects(result.projects);
                setSelectedProject(result.selectedProject);
                props.onProjectSelect(result.selectedProject);
            },
            (response) => {
                console.log(response);
            }
        );
    };

    React.useEffect(() => {
        loadActiveProject();
    }, []);

    const onProjectCreate = (result) => {
        loadActiveProject();
        handleClose();
    };

    const handleClose = () => {
        setProjectListDialogOpen(false)
        setProjectCreateDialogOpen(false)
    };

    const onProjectSelect = (selectedProject) => {
        handleClose();
        projectService.selectProject(selectedProject);
        loadActiveProject();
        props.onProjectSelect(selectedProject);
    };

    const handleOpenList = () => {
        setProjectListDialogOpen(true)
    };

    const handleOpenCreate = () => {
        setProjectCreateDialogOpen(true)
    };

    return (
        <div className={classes.root}>
            <Grid container direction="row" alignItems="flex-start">
                <div>
                    {selectedProject && (
                        <Button className={classes.button} startIcon={<AccountTree />} onClick={handleOpenList}>
                            {selectedProject.name}
                        </Button>
                    )}
                    {!selectedProject && !Boolean(projects.length) && (
                        <Button className={classes.button} startIcon={<AccountTree />} onClick={handleOpenCreate}>
                            {t("CREATE_PROJECT")}
                        </Button>
                    )}
                    {!selectedProject && Boolean(projects.length) && (
                        <Button className={classes.button} startIcon={<AccountTree />} onClick={handleOpenList}>
                            {t("SELECT_PROJECT")}
                        </Button>
                    )}
                </div>
            </Grid>
            {projectListDialogOpen && <ProjectListDialog open={projectListDialogOpen} onClose={handleClose} onProjectSelect={onProjectSelect} />}
            {projectCreateDialogOpen && (
                <ProjectCreateDialog open={projectCreateDialogOpen} onClose={handleClose} onProjectCreate={onProjectCreate} />
            )}
        </div>
    );

}

ProjectButton.propTypes = {
    onProjectSelect: PropTypes.func.isRequired,
};

export default ProjectButton;
