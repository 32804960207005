import yaml from 'js-yaml';
import deviceService from "../services/device";

function getCSRFToken() {
    return document.querySelector("[name=csrfmiddlewaretoken]").value;
}

function isValidJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

function isValidYaml(str) {
    try {
        yaml.load(str, {
            onWarning: (error) => {
                throw error;
            }
        });
    } catch (e) {
        return false;
    }
    return true;
}

function isHex(str) {
    var regex = /[0-9A-Fa-f]{6}/g;
    return !!str.match(regex)
}

/**
 * Checks wether the provided string is a valid public key for a psono instance
 * It needs to be hex encoded and 64 chars (or 256 bits)
 *
 * @param str
 * @returns {*|boolean}
 */
function isValidPsonoInstancePublicKey(str) {
    return isHex(str) && str.length === 64
}

function copyToClipboard(fetchContent) {
    if (deviceService.isSafari()) {
        // Safari
        return navigator.clipboard.write([
            new ClipboardItem({
                "text/plain": fetchContent(),
            }),
        ]);
    } else {
        // Firefox & Chrome and everything else
        return fetchContent().then((content) => navigator.clipboard.writeText(content))
    }
}

const service = {
    getCSRFToken,
    isValidJson,
    isValidYaml,
    copyToClipboard,
    isHex,
    isValidPsonoInstancePublicKey,
};

export default service;
