import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import ContentBody from "../../container/content-body";
import ContentHeader from "../../container/content-header";
import pricingService from "../../services/pricing";
import PsonoLicenseService from "../../services/psono-licenses";
import utils from "../../utils";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "inline",
    },
    h2: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    buttons: {
        marginTop: theme.spacing(2),
    },
    infoBox: {
        paddingTop: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            paddingTop: "0",
        },
    },
    backButton: {
        marginTop: "-12px",
        marginBottom: "-12px",
        marginLeft: "-12px",
    },
    checkboxHelperText: {
        marginBottom: "14px",
        marginLeft: "14px",
    },
    textField: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "440px",
        },
    },
}));

function PsonoLicenseCreate({ project }) {
    const history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation();
    const [pricing, setPricing] = useState({});
    const [name, setName] = useState("");
    const [publicKey, setPublicKey] = useState("");
    const [autoRenew, setAutoRenew] = useState(false);
    const [variation, setVariation] = useState("u25");
    const [errors, setErrors] = useState({});

    const loadPricing = () => {
        pricingService.getPricing(project.billing_account_id, project.id, "PSONO_LICENSE").then(
            (pricing) => {
                setPricing(pricing);
            },
            (response) => {
                console.log(response);
            }
        );
    }
    React.useEffect(() => {
        loadPricing();
    }, []);

    const calculateEstimatedMonthlyPrice = (variation) => {
        const pricingInfo = pricing[variation];
        const hoursPerMonth = (365 * 24) / 12;
        const monthTimeMultiplier = pricingInfo.per === "MINUTE" ? hoursPerMonth * 60 : NaN;
        return Number.parseFloat(pricingInfo.units * pricingInfo.price * monthTimeMultiplier).toFixed(2);
    };

    const onCreate = () => {
        PsonoLicenseService.createPsonoLicense(project.id, name, publicKey, variation, autoRenew).then(
            (response) => {
                history.push("/psono-license/detail/" + response.id + "/");
            },
            (errors) => {
                setErrors(errors)
            }
        );
    };

    return <>
        <ContentHeader>
            <Typography component="h1" variant="h5" className={classes.title}>
                <IconButton
                    onClick={() => {
                        history.goBack();
                    }}
                    color="primary"
                    aria-label="go back"
                    className={classes.backButton}
                    size="large">
                    <ArrowBackIcon />
                </IconButton>
                {t("CREATE_PSONO_LICENSE_FOR_ONE_YEAR")}
            </Typography>
        </ContentHeader>
        <ContentBody>
            <Grid container>
                <Grid item xs={12} md={8}>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                autoFocus
                                margin="dense" size="small"
                                required
                                id="name"
                                label={t("NAME")}
                                name="name"
                                autoComplete="name"
                                error={errors && "name" in errors}
                                helperText={errors && "name" in errors ? t(errors["name"][0]) : t("PSONO_LICENSE_NAME_EXPLANATION")}
                                value={name}
                                onChange={(event) => {
                                    setName(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                id="publicKey"
                                label={t("PUBLIC_KEY")}
                                name="publicKey"
                                autoComplete="publicKey"
                                error={errors && "public_key" in errors}
                                helperText={errors && "public_key" in errors ? t(errors["public_key"][0]) : t("PSONO_LICENSE_PUBLIC_KEY_EXPLANATION")}
                                value={publicKey}
                                onChange={(event) => {
                                    setPublicKey(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {Object.keys(pricing).length > 0 && (
                                <FormControl
                                    variant="outlined"
                                    className={classes.textField}
                                    margin="dense" size="small"
                                    required
                                    error={errors && "max_users" in errors}
                                >
                                    <InputLabel id="variation-label">{t("MAX_USERS")}</InputLabel>
                                    <Select
                                        labelId="variation-label"
                                        id="variation"
                                        value={variation}
                                        onChange={(event) => {
                                            setVariation(event.target.value);
                                        }}
                                        label={t("MAX_USERS")}
                                    >
                                        {Object.keys(pricing).map((key, index) => {
                                            return (
                                                <MenuItem key={key} value={key}>
                                                    {pricing[key].units} {t("USERS")}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {errors && "max_users" in errors && (
                                        <FormHelperText>{errors && "max_users" in errors ? t(errors["max_users"][0]) : ""}</FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="autoRenew"
                                        checked={autoRenew}
                                        onChange={(e) => {
                                            setAutoRenew(e.target.checked);
                                        }}
                                    />
                                }
                                label={t("AUTOMATIC_YEARLY_RENEWAL")}
                            />
                            <FormHelperText
                                 className={classes.checkboxHelperText}
                            >
                                {t("AUTOMATIC_YEARLY_RENEWAL_EXPLAINED")}
                            </FormHelperText>
                        </Grid>
                    </Grid>
                    <div className={classes.buttons}>
                        <Button variant="contained" color="primary" onClick={onCreate} disabled={!name || !publicKey || !utils.isValidPsonoInstancePublicKey(publicKey)}>
                            {t("CREATE")}
                        </Button>
                    </div>
                </Grid>
                {variation && pricing.hasOwnProperty(variation) && (
                    <Grid item xs={12} md={4}>
                        <div className={classes.infoBox}>
                            <Typography component="h2" variant="h6">
                                {t("PER_MONTH_ABOUT")} {pricing[variation].currency_symbol}{calculateEstimatedMonthlyPrice(variation)}
                            </Typography>
                            {t("PSONO_LICENSE_PRICING_INFO")}
                        </div>
                    </Grid>
                )}
            </Grid>
        </ContentBody>
    </>;

}

PsonoLicenseCreate.propTypes = {
    project: PropTypes.object.isRequired,
};

export default PsonoLicenseCreate;
