import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import ContentBody from "../../container/content-body";
import ContentHeader from "../../container/content-header";
import pricingService from "../../services/pricing";
import PsonoSaasInstanceService from "../../services/psono-saas-instances";
import PsonoSaasRegionSelectField from "../../components/psono-saas-region-select-field";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "inline",
    },
    h2: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    buttons: {
        marginTop: theme.spacing(2),
    },
    infoBox: {
        paddingTop: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            paddingTop: "0",
        },
    },
    backButton: {
        marginTop: "-12px",
        marginBottom: "-12px",
        marginLeft: "-12px",
    },
    textField: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "440px",
        },
    },
}));

function PsonoSaasInstanceCreate({ project }) {
    const history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation();

    const [psonoSaasRegionId, setPsonoSaasRegionId] = useState(null);
    const [pricing, setPricing] = useState({});
    const [variation, setVariation] = useState("u25");
    const [name, setName] = useState("");
    const [slug, setSlug] = useState("");
    const [errors, setErrors] = useState({});

    const loadPricing = () => {
        pricingService.getPricing(project.billing_account_id, project.id, "PSONO_SAAS_INSTANCE").then(
            (pricing) => {
                setPricing(pricing);
            },
            (response) => {
                console.log(response);
            }
        );
    }
    React.useEffect(() => {
        loadPricing();
    }, []);

    const calculateEstimatedMonthlyPrice = (variation) => {
        const pricingInfo = pricing[variation];
        const hoursPerMonth = (365 * 24) / 12;
        const monthTimeMultiplier = pricingInfo.per === "MINUTE" ? hoursPerMonth * 60 : NaN;
        return Number.parseFloat(pricingInfo.units * pricingInfo.price * monthTimeMultiplier).toFixed(2);
    };

    const onCreate = () => {
        PsonoSaasInstanceService.createPsonoSaasInstance(project.id, psonoSaasRegionId, name, slug, variation).then(
            (response) => {
                history.push("/psono-saas-instance/");
            },
            (errors) => {
                setErrors(errors);
            }
        );
    };
    return <>
        <ContentHeader>
            <Typography component="h1" variant="h5" className={classes.title}>
                <IconButton
                    onClick={() => {
                        history.goBack();
                    }}
                    color="primary"
                    aria-label="go back"
                    className={classes.backButton}
                    size="large">
                    <ArrowBackIcon />
                </IconButton>
                {t("CREATE_PSONO_SAAS_INSTANCE")}
            </Typography>
        </ContentHeader>
        <ContentBody>
            <Grid container>
                <Grid item xs={12} md={8}>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                autoFocus
                                margin="dense" size="small"
                                required
                                id="name"
                                label={t("NAME")}
                                name="name"
                                autoComplete="name"
                                error={errors && "name" in errors}
                                helperText={errors && "name" in errors ? t(errors["name"][0]) : t("PSONO_SAAS_INSTANCE_NAME_EXPLANATION")}
                                value={name}
                                onChange={(event) => {
                                    setName(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                id="slug"
                                label={t("SLUG")}
                                name="slug"
                                autoComplete="slug"
                                error={errors && "slug" in errors}
                                helperText={errors && "slug" in errors ? t(errors["slug"][0]) : t("PSONO_SAAS_INSTANCE_SLUG_EXPLANATION")}
                                value={slug}
                                onChange={(event) => {
                                    setSlug(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PsonoSaasRegionSelectField
                                className={classes.textField}
                                project={project}
                                variant="outlined"
                                margin="dense" size="small"
                                required
                                id="psono_saas_region_id"
                                error={errors && "psono_saas_region_id" in errors}
                                helperText={errors && "psono_saas_region_id" in errors ? t(errors["psono_saas_region_id"][0]) : ""}
                                value={psonoSaasRegionId}
                                onChange={(psonoSaasRegionId) => {
                                    setPsonoSaasRegionId(psonoSaasRegionId);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {Object.keys(pricing).length > 0 && (
                                <FormControl
                                    variant="outlined"
                                    className={classes.textField}
                                    margin="dense" size="small"
                                    required
                                    error={errors && "max_users" in errors}
                                >
                                    <InputLabel id="variation-label">{t("MAX_USERS")}</InputLabel>
                                    <Select
                                        labelId="variation-label"
                                        id="variation"
                                        value={variation}
                                        onChange={(event) => {
                                            setVariation(event.target.value);
                                        }}
                                        label={t("MAX_USERS")}
                                    >
                                        {Object.keys(pricing).map((key, index) => {
                                            return (
                                                <MenuItem key={key} value={key}>
                                                    {pricing[key].units} {t("USERS")}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {errors && "max_users" in errors && (
                                        <FormHelperText>{errors && "max_users" in errors ? t(errors["max_users"][0]) : ""}</FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        </Grid>
                    </Grid>
                    <div className={classes.buttons}>
                        <Button variant="contained" color="primary" onClick={onCreate}>
                            {t("CREATE")}
                        </Button>
                    </div>
                </Grid>
                {variation && pricing.hasOwnProperty(variation) && (
                    <Grid item xs={12} md={4}>
                        <div className={classes.infoBox}>
                            <Typography component="h2" variant="h6">
                                {t("PER_MONTH_ABOUT")} {pricing[variation].currency_symbol}{calculateEstimatedMonthlyPrice(variation)}
                            </Typography>
                            {t("PSONO_SAAS_INSTANCE_PRICING_INFO")}
                        </div>
                    </Grid>
                )}
            </Grid>
        </ContentBody>
    </>;

}

PsonoSaasInstanceCreate.propTypes = {
    project: PropTypes.object.isRequired,
};

export default PsonoSaasInstanceCreate;
